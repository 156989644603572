import React, { createContext, useState, useContext, useEffect } from 'react';
import { useUnleashContext } from '@unleash/proxy-client-react';
import api from '../lib/utils/api';

interface AuthContextData {
  token: string;
}
const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const [token, _setToken] = useState(() => {
    const token = sessionStorage.getItem('accessToken');
    if (token) {
      api.defaults.headers.authorization = token;

      return token;
    }
    return '';
  });

  const updateContext = useUnleashContext();
  const userId = window.sessionStorage.getItem('accountId');
  const orgDetail = JSON.parse(window.sessionStorage.getItem('orgDetail'));
  const orgId = orgDetail?.id;

  useEffect(() => {
    async function run() {
      await updateContext({
        userId,
        properties: {
          domainName: window.location.hostname,
          ...(orgId && { organizationId: orgId }),
        },
      });
    }
    run();
  }, [userId]);

  return (
    <AuthContext.Provider value={{ token }}>{children}</AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  return context;
}

export { AuthProvider, useAuth };
