import {
  APIDeactivateParams,
  APIInviteParams,
  APIReactivateParams,
  APIResendInviteParams,
  ResendInviteResponse,
} from '../../@types';
import {
  API_DEACTIVATE,
  API_INVITE,
  API_REACTIVATE,
  API_RESEND_INVITE,
} from '../constants';

//TODO move this to controllers in copilot-mfa-communication.
import API from '../../lib/utils/api';
export const isLocal = window.location.hostname === 'localhost';
export const KNOWLEDGE_BASE_LINK =
  'https://developer.fabric.inc/v3/guides/get-started/copilot-overview';
export const API_DOC_LINK =
  'https://developer.fabric.inc/v3/api-reference/getting-started/overview';
export const HEADLESS_RESOURCES_LINK = 'https://resources.fabric.inc/';

export const resendInviteRequest = async (
  apiRequestParams: APIResendInviteParams
): Promise<ResendInviteResponse> => {
  const response = await API.post(API_RESEND_INVITE, {
    userId: apiRequestParams.userId,
    senderId: apiRequestParams.senderId,
  });
  return response?.data;
};

export const reactivateRequest = async (
  apiRequestParams: APIReactivateParams
) => {
  const response = await API.post(API_REACTIVATE, {
    userId: apiRequestParams.userId,
  });
  return response?.data;
};

export const deactivateRequest = async (
  apiRequestParams: APIDeactivateParams
) => {
  const response = await API.post(API_DEACTIVATE, {
    userId: apiRequestParams.userId,
  });
  return response?.data;
};

export const inviteRequest = async (apiRequestParams: APIInviteParams) => {
  const response = await API.post(
    `${API_INVITE}${apiRequestParams.body.accountId}`
  );
  return response?.data;
};
