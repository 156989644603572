import { COPILOT_ROUTES } from '@teamfabric/copilot-utilities';

export const DEFAULT_ROUTE = {
  current: '/admin',
  new: COPILOT_ROUTES.home.basePath,
};

export const ACTIVITY_ROUTE = {
  current: '/admin/activity',
  new: COPILOT_ROUTES.home.activity,
};
export const SETTINGS_ROUTE = {
  current: '/admin/settings',
  new: '/home/settings',
};
export const ACCOUNT_SETTINGS_ROUTE = {
  current: '/admin/account-details',
  new: '/home/account-details',
};
export const NEW_ACCOUNT_REQUEST_ROUTE = {
  current: '/admin/account-details/new-account',
  new: '/home/account-details/request-new-account',
};
export const MANAGE_CUSTOMERS_ROUTE = {
  current: '/admin/customers',
  new: '/home/customers',
};
export const MANAGE_USERS_ROUTE = {
  current: '/admin/users-list',
  new: '/home/users-list',
};
export const ANALYTICS_ROUTE = {
  current: '/admin/analytics',
  new: COPILOT_ROUTES.home.analytics,
};
export const API_APPS_ROUTE = {
  current: '/admin/developer-tools/app/api/',
  new: '/home/developer-tools/app/api/',
};
export const CREATE_API_APPS_ROUTE = {
  current: '/admin/developer-tools/app/api/create',
  new: '/home/developer-tools/app/api/create',
};
export const EDIT_API_APPS_ROUTE = {
  current: '/admin/developer-tools/app/api/edit/:appid',
  new: '/home/developer-tools/app/api/edit/:appid',
};

export const ANALYTICS_DASHBOARD_URL =
  'https://fabricinc-dev.sisense.com/wat/eyJraWQiOiI2MWZkYmFiZDNiNjU4YzAwMzZjNDNkYjYiLCJ0eXAiOiJKV1QiLCJhbGciOiJSU0EtT0FFUC0yNTYiLCJlbmMiOiJBMTI4R0NNIiwiemlwIjoiREVGIn0.cRpQSNsK48K_w2v365ZiEiiHB9mEdD_Qy5_vKV0WjwUL1Q8QphQHNvwhLjgPgCLosoZg5FLTsztRMocFSAhegOzWnJzfk68DCeF-gOnLyBkwdmfZ0hcnupruwC7YC3NcfPjIkcAy_xBQ1rV0UtvpJbXSv5GmDvoO4VW-bKKFFRBiYBtnBONZSJ2jxi5-pukEtie6HnTqG-YxiYjvtM7s3se_I_M2-KcTVTcuohSebc6IIaQY4prdl-pb3SAckCRXc4zGGi6D8CP7u2yAvV9A1PwOgpE7fgOsZDMm6t38wH-AcXclHdCxVshauJ8ZOP-dLcT3fGqB036G8r61UoA1FA.6OxrP8pOkQqLaiyD.N4tp2Dve38aZd6T2qxzXWgucB14gKRJtLtal-iEe3odAsP5tPUvbWsqdubSNPQseWxnk3_OF3zWrQkcZihPGZg1BJP2gjNP6tU2NKGwcbPz0kjFv.mRvfXBbr32BlF4yNsZezhg/app/main#/dashboards/618552e4be855b003587c650?embed=true&theme=61bd1008b881390036159a4c';
export const LOGIN_ROUTE = '/auth/login';
export const MIN_LENGTH = 0;
export const API_LOGIN_USER = 'auth/local/login';
export const API_RESEND_INVITE = 'user/re-send/invite';
export const API_REACTIVATE = 'user/reactivate';
export const API_DEACTIVATE = 'user/deactivate';
export const API_INVITE = 'user/invite/';
export const GET_ALL_SUPPORTED_LOCALES_V3 = '/available-locales';
export const GET_ALL_SUPPORTED_LOCALES = '/supported-locales';
export const MERCHANT_LOCALES = '/merchant-configurations/locales';
export const MERCHANT_CURRENCIES = '/merchant-configurations/currencies';
export const MERCHANT_LOCALES_V3 = '/merchant-settings/locales';
export const MERCHANT_CURRENCIES_V3 = '/merchant-settings/currencies';
export const LOCALES_TOASTR_MESSAGE =
  'Locale "{locale_code}" added successfully.';
export const LOCALES_DEFAULT_TOASTR_MESSAGE =
  'Default locale changed to {locale_code} successfully.';
export const GET_ALL_SUPPORTED_CURRENCIES = '/supported-currencies';
export const GET_ALL_SUPPORTED_CURRENCIES_V3 = '/available-currencies';
export const TOASTER_TIMEOUT = 5000;
export const LEGACY_DEFAULT_CHANNEL = 12;
export const DATE_FORMAT = 'MMM dd, yyyy';
export const SUCCESS_TOASTR_KIND = 'success';
export const ERROR_TOASTR_KIND = 'alert';
export const MESSAGE_TOASTR_KIND = 'message';
export const LOCALE_HEADER_TITLE = 'Locale details';
export const LOCALE_ADD_BUTTON_TEXT = 'Add locale';
export const DEV_BASE_URL = 'https://dev.na.channel.fabric.zone/v1/';
export const REFRESH_API_RETRIES = 2;
export const V3_MERCHANT_LOCALES_FLAG = 'v3-merchant-locales';

export enum LOCAL_STORAGE_KEYS {
  IS_LOGGEDIN_WITH_V2 = 'isLoggedInWithIdV2',
  OKTA_TOKEN_STORAGE = 'okta-token-storage',
}

export enum SESSION_STORAGE_KEYS {
  ACCOUNT_ID = 'accountId',
  ACCOUNT = 'account',
  ACCESS_TOKEN = 'accessToken',
  ORG_DETAIL = 'orgDetail',
  TENANT_DETAIL = 'tenantDetail',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
}

export const sunsetBannerText =
  'New Copilot features are available! Check out this blog post to learn how to use them.';
export const inviteUserBannerText =
  'Please log in through IDv2 in order to invite new users to your organization.';
export const getUpdatedMessage = (name: string) =>
  `${name} updated successfully`;
export const getUpdatedErrorMessage = (name: string) =>
  `Error updating ${name}`;
