import axios from 'axios';
import { isLocal } from '../helpers';

const api = axios.create({
  baseURL: isLocal ? `https://dev02.copilot.fabric.inc` : `/`,
  responseType: 'json',
  headers: {
    'x-site-context': JSON.stringify({
      date: new Date().toISOString(),
      channel: 12,
      account:
        localStorage.getItem('isLoggedInWithIdV2') === 'true'
          ? sessionStorage.getItem('accountId')
          : sessionStorage.getItem('account'),
    }),
  },
});

export default api;
